:root {
  --checkbox-color: #EAE2D6; 
  --checkbox--checked-color: #2196F3;
  --concept-entry-block-color: #EAE2D6; 
  --header-color: #B8C4BA;
  --metadata__button-color: #D8D8D8;
  --metadata-term__blink__active-color: #EAE2D6;
  --popup-color: #EAE2D6;
  --term-block-color: #EAE2D6;
  --term-block__lang-button-color: #A1A1A1;

  --font-family: helvetica, arial, verdana, sans-serif; 
}

html {
  height: 98vh;
  width: 100vw;
  font-family: var(--font-family);
  /* min-hight: 930px; */
}

body {
  height: 100%;
  min-height: 650px;
  min-width: 1600px;
}

#root {
  height: 100%;
}

.error	{
  color: red;
  font-weight: bold;
}

.main-window	{
  min-height: 50%;
  min-width: 60%;
  height: 100%;
}

.content-window {
  min-height: 50%;
  min-width: 60%;
  height: 98%;
  display: grid;
  grid-template: auto 90% auto / 25% auto;
}

.footer {
  margin: 10px;
  width: 99%;
  grid-row: 3;
  grid-column: 1/3;
}

.privacy-policy {
  display: inline-block;
  margin-left: 25px;
}

.app-version {
  float: right;
}

.blur {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.backdrop	{
  background-color: rgba(100,100,100,.9);
  display: flex;
  position: fixed;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.metabackdrop {
  display: flex;
  width: 60%;
}

.popup	{
  display: flex;
  justify-content: center;
  align-self: center;
  bottom: 100px;
  width: 600px;
  height: 50%;
  min-height:350px;
  background-color: var(--popup-color);
}

@media only screen and (max-height: 710px) {
  .popup {
  }
}

@media only screen and (max-height: 490px) {
  .popup {
  }

  .popup__content	{
  }

  .card-title__popup {
  }
}

@media only screen and (max-height: 430px) {
  .popup {
  }
}

@media only screen and (max-height: 325px) {
  .popup {
  }
  .popup_content {
  }

  .checkbox {
  }

  .language-checkbox {
  }

  .checkbox--checked .check {
  }
  
  .language-checkbox__label {
  }
  
  .language-checkbox__label____content {
  }
}

.popup--buttons	{
  height: 40%;
}

.popup__content	{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  width: 92%;
  height: 92%;
  background-color: white;
}

.popup--buttons .popup__content {

}

.popup__content > div:first-of-type {
  height: 80%;
  overflow-y: auto;
}

.popup__content__checkboxContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.popup__content__checkAllContainer {
  display: flex;
  grid-row: 1;
  grid-column: 1/4;
}

.popup__content__checkboxContainer > .language-checkbox {
  grid-row-start: 2;
}

.popup__content__checkboxContainer > .language-checkbox {
  grid-row: auto;
}

.language-checkbox {
  padding: 10px;

}

.language-checkbox__label input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox {
  display: inline-block;
  position: relative;
  margin-right: 10px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--checkbox-color);
}

.checkbox .check {
  content: "";
  position: absolute;
  display: none;
}

.checkbox--checked {
  background-color: var(--checkbox--checked-color);
}

.checkbox--checked .check {
  display: block;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.language-checkbox__label {
  display: grid;
  grid-template: 1fr / 30px auto;
  align-items: center;;
}

.language-checkbox:hover {
  background-color: #ccc;
}

/* .language-checkbox__label input {
  margin: 0 12px;
} */

.language-checkbox__label____content {
  font-size: 1.5em;
  vertical-align: top;
}

.popup__content____message {
  align-content: center;

  width: 85%;
}

.popup__content____message--large {
  font-size: 1.5em;
}

.popup__content____buttonblock {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  position: relative;
  width: 100%;
}

.popup__content____buttonblock--single {
  width: 60%;
}

.popup__content____buttonblock button {
  font-size: 1.25em;
  height: 55px;
}

.popup__content____buttonblock--single button {
  width: 100%;
}

.popup__schema	{

}

.file-uploader {
  display: grid;
  height: 200px;
  width: 650px;
  justify-content: center;
  align-self: center;
  grid-template: 1fr auto / 1fr;
  position: relative;
}

.file-uploader > input {
  position: absolute;
}

.upload-header	{
  background-color: white;
  border-radius: 5px;
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template: calc(50% - 4px) auto / 1fr 3fr;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin-bottom: 25px;
}

.upload-header__h1	{
  display: block;
  grid-row: 1/3;
  align-self: center;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-weight: lighter;
  text-align: center;
  color: rgba(100,100,100,.9);
  width: 30%;
  font-size: 6em;
}
.upload-header__p	{
  grid-row: 1;
  grid-column: 2;
  margin-left: 15px;
  height: 30%;
  width: 85%;
  color: rgba(100,100,100,.9);
  font-size: 30px;
  font-family: sans-serif;
}

.upload-header__span {
  grid-row: 2;
  grid-column: 2;
  background-color:rgba(100,100,100,.9);
  align-self: start;
  width: 90%;
  height: 4px;
  border-radius: 15px;
}

.upload-button	{
  background-color: white;
  position: relative;
  height: 65px;
  width: 285px;
  border-radius: 5px;
  text-align: center;
}

.upload-button span {
  color: rgba(100,100,100,.9);
}

.upload-button__label	{
  justify-self: center;
  padding-top: 15px;
}

.x-small {
  font-size: x-small;
}

.indented	{
  margin-left: 16px;
}

.indented--double	{
  margin-left: 32px;
}

.term	{
  font-size: 20px;
  font-weight: bold;
}

.card-title	{
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid gray;
  min-width: 450px;
  width: 30%;
}
.card-title__popup	{
  align-self: baseline;
  margin: 0;
  margin-right: auto;
  padding: 0;
  font-size: 32px;
  padding-left: 5px;
}

.concept-card	{
  background-color: white;
  border-radius: 3px;
  margin: 0 10px;
  padding: 10px;
}

.concept-card > div:last-of-type {
  height: 15px;
}

.lang-card	{
  background-color: white;
  border-radius: 3px;
  margin: 0 10px;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 10px;
}

.metadata-item__container	{
  margin: 0;
  padding: 0;
}
.metadata-item__key	{
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  font-size: 16px;
  width: 160px;
}
.metadata-item__value	{
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 70%;
  word-wrap: break-word;
}

.metadata-item__blink:active {
  background-color: var(--metadata-term__blink__active-color);
}

.header	{
  width: 100%;
  height: 60px;
  background-color: var(--header-color); 
  color: black;
  grid-row: 1;
  grid-column: 1/3;
}

.header-data__button-block	{
  float: right;
}

.metadata__button	{
  background-color: var(--metadata__button-color);
  border: 0;
  margin: 15px;
  height: 30px;
  width: 100px;
}

.header-data__block	{
  display: inline-block;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 30px;
  text-align: center;
}

.header-data__dc-name	{
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.header-data__dc-value	{
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.concept-entry-block	{
  display: flex;
  justify-content: center;
  background-color: var(--concept-entry-block-color);
  height: 100%;
  margin-top: 5px;
  overflow: auto;
  grid-row: 2;
  grid-column: 2;
}

.concept-entry-block__contents	{
  margin: 15px;
  width: 100%;
}

.concept-entry-block__contents--empty {
  align-self: center;
  position: relative;
  bottom: 60px;
  width: 500px;
  font-size: 2em;
  font-weight: bold;
}

.term-block	{
  background-color: var(--term-block-color);
  height: 100%;
  width: 95%;
  min-width: 206px;
  margin-top: 5px;
  padding-left: 10px;
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template: 5px 25px 25px auto 1px / 80% 36px 36px 15px;
}

.term-block > * {
  min-height: 0;
}

.term-block__title	{
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid gray;
  grid-row: 2;
  grid-column: 1/2;
  display: grid;
  align-content: end;
}

.term-block__search	{
  background-color: white;
  height: 26px;
  border: 1px solid gray;
  padding-left: 5px;
  border-radius: 3px;
  grid-row: 3;
  grid-column: 1/2;
}

.term-block__top-buttons {
  height: 26px;
  width: 26px;
  padding: 0;
  font-size: .95em;
  grid-row: 3;
  justify-self: right;
}

@media only screen and (max-width: 1300px) {
  .term-block__list-container {

  }

  .term-block__top-buttons {
  }
}

.term-block__top-buttons span {
  display: block;
  background-color: transparent;
  position: relative;
  height: 50%;
  width: 50%;
  margin: 0 auto;
  border: solid black;
  border-width: 0 3px 3px 0;
}

.term-block__collapse-button {
  grid-column: 2;
}

.term-block__collapse-button span {
  left: 4px;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.term-block__uncollapse-button {
  grid-column: 3;
}

.term-block__uncollapse-button  span{
  bottom: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.term-block__list-container	{
  background-color: white;
  height: 95%;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid gray;
  border-radius: 3px;
  grid-row: 4;
  grid-column: 1/4;
}

.term-block__list-container____list	{
  background-color: white;
  height: 92%;
  overflow: auto;
}

.term-block__list-line	{
  width: 60%;
  border-bottom: 1px solid #A1A1A1;
}

.collapse-arrow-down	{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #A1A1A1;
}

.collapse-arrow-left	{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 10px;
  border-right: 6px solid #A1A1A1;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.term-block__lang-button	{
  background-color: var(--term-block__lang-button-color);
  margin-top: 5px;
  border: 0;
  width: 100px;
  height: 30px;
}

.term-block__lang-block	{
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 15px;
}

.term-block__lang-block____name {
  cursor: pointer;
}

.term-block__list-item	{
  padding: 0;
  margin: 1px;
}

.term-block__list-item:hover	{
  cursor: pointer;
}
